import React, {useEffect} from 'react';
import styled from "styled-components";
import {ForwardIcon} from "../../assets/img/icons";
import {useDispatch, useSelector} from "react-redux";
import {getConfig, removeConfig} from "../../store/actions/purchases";
import {AppRootState} from "../../store";
import { PaymentMethod, paymentMethods } from '../Buy/payments';
import { Button } from 'src/libs';
import { mainApi } from 'src/helpers/api';

export default React.memo(function Updater(props: {
  mode: 'in-list' | 'in-item',
  discount: number,
  price: number,
  userId: number,
  productId: number,
  prognosisKey: string,
  setRedirectUrl?(value: string): void
}) {
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(paymentMethods[0])
  const dispatch = useDispatch()
  const paymentConfig = useSelector<AppRootState, any>(state => state.purchases.config)
  const productIdFromPaymentConfig = paymentConfig?.data.formations[0].formationProductId
  const finalPrice = Math.floor(props.price - props.price * props.discount)

  const onSuccess = () => {
    props.setRedirectUrl && props.setRedirectUrl(`/prognosis/${props.userId}/${props.prognosisKey}`)
  }

  const onCancel = (error: string) => {
    dispatch(removeConfig())
    if (error === 'User has cancelled') return
    alert('Оплата не прошла, обратитесь в техническую поддержку')
  }

  async function buy() {
    await mainApi.payment(paymentConfig, 'charge', {onSuccess, onCancel})
  }

  useEffect(() => {
    if (paymentConfig && productIdFromPaymentConfig === props.productId) {
      buy()
    }
  }, [paymentConfig])

  const onclickHandler = () => {
    const cart = [{
      formationId: props.userId,
      formationProductId: props.productId,
    }]
    window.ym(49958389, 'reachGoal', `update_${props.prognosisKey}_dawn${props.mode === 'in-item' ? `-in-${props.prognosisKey}` : ''}`)
    dispatch(getConfig(cart, paymentMethod.country))
  }

  if (props.mode === 'in-list') {
    return (
      <ListContainer onClick={onclickHandler}>
        <div className={'left'}>
          <span>Доступно обновление</span>{props.discount > 0 && <Badge>-{props.discount * 100}%</Badge>}
        </div>
        <div className={'right'}>
          <p>Обновить за <Price hasDiscount={props.discount > 0}>{props.price}₽</Price>
            {props.discount > 0 && <span> {finalPrice}₽</span>}
          </p> <ForwardIcon/>
        </div>
      </ListContainer>
    )
  }

  return (
    <ItemContainer>
      <div className={'left'}>
        <h2>Обновите документ со скидкой</h2>
        <div>Мы выпустили крупное обновление для документа и специально для вас подготовили скидку</div>
          {
            props.discount > 0
              ? <div className={'amounts'}>
                  <p>
                    <Price hasDiscount={props.discount > 0}>{props.price}</Price><span className={'finalPrice'}> {finalPrice}₽</span>
                  </p>
                  <Badge>-{props.discount * 100}%</Badge>
                </div>
              : <div className={'amounts'}><span className={'finalPrice'}>{props.price}₽</span></div>
          }
      </div>
      <div className={'right'}>
        <Button color={'var(--color-sea)'}
                size={'small'}
                className={'button'}
                onClick={onclickHandler}>Перейти к оплате</Button>
      </div>
    </ItemContainer>
  );
})

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;
  background: var(--color-sea);
  font-size: 1.2rem;
  padding: 0 2rem;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  .left {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      span {
        width: 50%;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
  }

  .price{
    text-decoration: line-through;
  }

  &:hover {
    box-shadow: 0px 1.8px 5.4px rgba(0, 0, 0, 0.09), 0px 9.6px 21.6px rgba(0, 0, 0, 0.05);
  }
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;

  color: var(--text-secondary);
  background: var(--color-sea-bg);
  padding: 2rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(61, 189, 166, 0.3);
  border-radius: 8px;

  p {
    margin: 0;
  }

  .left {
    display: flex;
    flex-direction: column;

    width: 60%;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }

  .amounts {
    display: flex;
    justify-content: start;
    align-items: center;

    color: var(--text-primary);
    margin-top: 1rem;
  }

  .price{
    text-decoration: line-through;
  }

  .finalPrice {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .right {
    display: flex;
    align-items: end;

    @media (max-width: 768px) {
      justify-content: end;
    }
  }

  .button {
    padding: 0.9rem 2.5rem;
    opacity: 1;
    border-radius: 0.375rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    padding: 1.5rem;

    .button {
      font-size: 1rem;
      padding: 0.9rem 1rem
    }
  }
`

const Badge = styled.div`
  font-size: 1rem;
  color: white;
  background: var(--color-orange);
  padding: 0.2rem 0.6rem;
  border-radius: 127px;
  margin-left: 1rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin-left: 0;
  }
`

const Price = styled.span<{hasDiscount: boolean}>`
  text-decoration: ${props => props.hasDiscount ? 'line-through' : 'none'};
`
